angular.module('promotion_service', [])
    .factory('PromotionService', ['MyAccount', 'myConfig', '$location', '$http',
        function (MyAccount, myConfig, $location, $http) {

            const self = this;

            let staticHostUrl = myConfig.production ? myConfig.middlewareHost : myConfig.host;
            if (staticHostUrl.substr(-1) === '/') {
                staticHostUrl = staticHostUrl.substr(0, staticHostUrl.length - 1);
            }

            window.addEventListener('myaccount-login', () => {
                self.checkAvailablePromotions().finally();
            });

            window.addEventListener('myaccount-logout',  () => {
                self.checkAvailablePromotions().finally();
            });

            function checkAvailablePromotions() {
                return new Promise((resolve) => {

                    if ($location.search().disableAutomaticPromotion) {
                        console.log('Automatic promotion disabled');
                        $location.search('disableAutomaticPromotion', null);
                        $location.search('promotionCode', null);
                        resolve();
                        return;
                    }

                    const myAccountPersonId = MyAccount.getMyAccountPersonId();
                    const myAccountPerson = MyAccount.getPerson();
                    if (myAccountPersonId != null && myAccountPerson != null) {
                        $http.get(staticHostUrl + "/api/myAccount/promotion",
                            {
                                params: {
                                    idProperty: myConfig.idProperty,
                                    myAccountPersonId: myAccountPersonId,
                                    myAccountPersonEmail: myAccountPerson.email,
                                    myAccountPersonLastName: myAccountPerson.lastName,
                                    promotionCode: myConfig.promotionCode != null ? myConfig.promotionCode.replace(/\s/g, '_') : null
                                }
                            })
                            .success(function (data) {
                                const promotionCode = data.promotionCode != null ? data.promotionCode.replace(/\s/g, '_') : null;
                                myConfig.promotionCode = promotionCode;
                                $location.search('promotionCode', promotionCode);
                                resolve(data);
                            })
                            .error(function (err) {
                                console.error(err);
                                resolve();
                            });
                    } else {
                        resolve();
                    }
                });
            }

            self.checkAvailablePromotions = checkAvailablePromotions;

            return {
                checkAvailablePromotions
            };
        }
    ]);
